<template>
  <div class="home">
    <div class="home-statistics">
      <!-- 顶部统计栏 -->
      <div class="home-statistics-top">
        <el-row>
          <el-col :span="6">
            <div class="item">
              <el-row>
                <el-col :span="12">
                  <div class="statistics-item">
                    <div class="statistics-item-num">
                      ￥{{ this.orderData.allOrdersTotalAmount }}
                    </div>
                    <div class="statistics-item-num">总收入</div>
                    <div class="statistics-item-num">
                      <span
                        :class="
                          this.orderData.amountGrowthRate > 0
                            ? 'boxred'
                            : 'boxyellwo'
                        "
                        >{{ this.orderData.amountGrowthRate }}%
                        <i v-if="this.orderData.amountGrowthRate > 0">↑</i>
                        <i v-else>↓</i>
                      </span>
                    </div>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="statistics-item">
                    <img
                      :src="require('@/assets/img/icon-shouru.png')"
                      alt=""
                    />
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item">
              <el-row>
                <el-col :span="12">
                  <div class="statistics-item">
                    <div class="statistics-item-num">
                      {{ this.orderData.allOrdersCount }}
                    </div>
                    <div class="statistics-item-num">总订单</div>
                    <div class="statistics-item-num">
                      <span
                        :class="
                          this.orderData.amountGrowthRate > 0
                            ? 'boxred'
                            : 'boxyellwo'
                        "
                        >{{ this.orderData.amountGrowthRate }}%
                        <i v-if="this.orderData.amountGrowthRate > 0">↑</i>
                        <i v-else>↓</i>
                      </span>
                    </div>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="statistics-item">
                    <img :src="require('@/assets/img/icon-kehu.png')" alt="" />
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item">
              <el-row>
                <el-col :span="12">
                  <div class="statistics-item">
                    <div class="statistics-item-num">1035人</div>
                    <div class="statistics-item-num">总会员数</div>
                    <div class="statistics-item-num">22.45%↑</div>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="statistics-item">
                    <img
                      :src="require('@/assets/img/icon-huiyuan.png')"
                      alt=""
                    />
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item">
              <el-row>
                <el-col :span="12">
                  <div class="statistics-item">
                    <div class="statistics-item-num">1035人</div>
                    <div class="statistics-item-num">总管理员数</div>
                    <div class="statistics-item-num">22.45%↑</div>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="statistics-item">
                    <img :src="require('@/assets/img/icon-user.png')" alt="" />
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>
      </div>

      <!-- 中间图标 -->
      <div class="home-statistics-echarts" ref="chart"></div>

      <!-- 底部列表 -->
      <div class="home-statistics-list">
        <el-row :gutter="40">
          <el-col :span="12">
            <div class="home-statistics-list-left">
              <div class="home-statistics-list-left-title">最新订单</div>
              <el-table :data="tableData" style="width: 100%">
                <el-table-column label="时间">
                  <template slot-scope="scope">
                    <span>{{ $formatDate(scope.row.createdAt) }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="username" label="用户名" width="150">
                </el-table-column>
                <el-table-column prop="price" label="金额(元)" width="150">
                </el-table-column>
                <el-table-column label="订单状态" width="150">
                  <template slot-scope="scope">
                    <span :class="getStatusClass(scope.row.paymentStatus)">
                      {{ getStatusText(scope.row.paymentStatus) }}
                    </span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-col>

          <el-col :span="12">
            <div class="home-statistics-list-right home-statistics-list-left">
              <div class="home-statistics-list-left-title">畅销商品</div>
              <el-table :data="shopData" style="width: 100%">
                <el-table-column label="商品名称">
                  <template slot-scope="scope">
                    <div class="product-item">
                      <img
                        :src="scope.row.image"
                        alt=""
                        class="product-image"
                      />
                      <span>{{ scope.row.name }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="price" label="价格(元)" width="150">
                </el-table-column>
                <el-table-column label="下单总数(个)" width="150">
                  <template slot-scope="scope">
                    <span>
                      {{ scope.row.num || scope.row.id }}
                    </span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { getOrdersStic, getOrdersList, getShopList } from "@/api/api";
export default {
  name: "HomeView",
  data() {
    return {
      tableData: [
        {
          createdAt: "2024-08-13T13:21:52.000Z",
          id: 200,
          paymentStatus: 2,
          price: "520",
          title: "Order Title 100",
          updatedAt: "2024-08-13T13:21:53.000Z",
          username: "User100",
        },
      ],
      shopData: [
        {
          img: require("@/assets/img/admin.png"),
          title: "商品1",
          price: "330元",
          quantity: "150",
        },
        {
          img: require("@/assets/img/admin.png"),
          title: "商品2",
          price: "440元",
          quantity: "200",
        },
        {
          img: require("@/assets/img/admin.png"),
          title: "商品3",
          price: "550元",
          quantity: "250",
        },
        {
          img: require("@/assets/img/admin.png"),
          title: "商品4",
          price: "660元",
          quantity: "300",
        },
      ],
      orderData: {},
    };
  },
  // 在实例初始化之后立即调用
  beforeCreate() {
    console.log("beforeCreate - 数据还未初始化");
  },

  // 在实例创建完成后被立即调用
  created() {
    console.log("created - 数据已经初始化，但 DOM 还未生成");
    this.initData();
  },

  // 在挂载开始之前被调用
  beforeMount() {
    console.log("beforeMount - 即将挂载到 DOM");
  },

  // el 被新创建的 vm.el 替换，并挂载到实例上去之后调用
  mounted() {
    console.log("mounted - 已经挂载到 DOM");
  },

  // 数据更新之前
  beforeUpdate() {
    console.log("beforeUpdate - 数据即将更新");
  },

  // 数据更新之后
  updated() {
    console.log("updated - 数据已经更新，DOM 已经重绘");
  },

  // 触发实例销毁之前
  beforeDestroy() {
    console.log("beforeDestroy - 实例即将销毁");
  },

  // Vue 实例销毁后调用
  destroyed() {
    console.log("destroyed - 实例已经被销毁");
  },

  methods: {
    initData() {
      getOrdersStic().then((res) => {
        this.orderData = res.data.data;
        this.initChart();
      });
      const params = {
        page: 1,
        pageSize: 4,
      };
      getOrdersList(params).then((res) => {
        this.tableData = res.data.data.data;
      });
      getShopList(params).then((res) => {
        console.log("畅销商品", res.data.data);
        this.shopData = res.data.data;
      });
    },
    getStatusText(status) {
      if (status === 0) return "已支付";
      if (status === 1) return "未支付";
      if (status === 2) return "支付失败";
      if (status === 3) return "待支付";
      return "未知状态";
    },
    getStatusClass(status) {
      if (status === 0) return "paid";
      if (status === 1) return "unpaid";
      if (status === 2) return "failed";
      if (status === 3) return "unpaid";
      if (status === 4) return "undef";
      return "未知状态";
    },
    initChart() {
      const chartDom = this.$refs.chart;
      const myChart = echarts.init(chartDom);
      const option = {
        tooltip: {},
        legend: {
          data: ["每月订单", "每月会员", "每月收入"], // 对换后的内容
          itemWidth: 15, // 设置图例中圆圈的宽度
          itemHeight: 8, // 设置图例中圆圈的高度
          itemStyle: {
            color: "#fff", // 设置图例中圆圈的背景颜色
          },
        },
        xAxis: {
          type: "category", // 类目轴
          data: this.orderData.Ordermonths,
          boundaryGap: false, // 关闭边界间隙
          min: "dataMin", // 确保 x 轴的起点是数据的起点
          max: "dataMax", // 确保 x 轴的终点是数据的终点
          splitLine: {
            show: false, // 隐藏 x 轴上的分割线
          },
        },
        yAxis: {
          type: "value", // 数值轴
          axisLine: {
            show: true, // 显示 y 轴线
            lineStyle: {
              color: "#333", // y 轴线的颜色
            },
          },
        },
        series: [
          {
            name: "每月订单",
            type: "line",
            data: this.orderData.Ordercounts, // 模拟数据
            smooth: true, // 平滑曲线
            symbol: "circle", // 数据点的图形
            symbolSize: 5, // 数据点大小
            itemStyle: {
              // 控制数据点的样式
              color: "transparent", // 数据点背景色透明
              borderColor: "#409eff", // 边框颜色
              borderWidth: 1, // 边框宽度
            },
            lineStyle: {
              width: 2, // 曲线宽度
              color: "#409eff", // 曲线颜色
            },
            areaStyle: {
              // 曲线下方的填充
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  // {
                  //   offset: 0,
                  //   color: "rgb(255, 158, 68)", // 0% 处的颜色
                  // },
                  // {
                  //   offset: 1,
                  //   color: "rgb(165, 82, 38)", // 100% 处的颜色
                  // },
                ],
                globalCoord: false, // 缺省为 false
              },
            },
          },
          {
            name: "每月收入",
            type: "line",
            data: this.orderData.Orderamounts, // 模拟数据
            smooth: true, // 平滑曲线
            symbol: "circle", // 数据点的图形
            symbolSize: 5, // 数据点大小
            itemStyle: {
              // 控制数据点的样式
              color: "transparent", // 数据点背景色透明
              borderColor: "#409eff", // 边框颜色
              borderWidth: 1, // 边框宽度
            },
            lineStyle: {
              width: 2, // 曲线宽度
              color: "#409eff", // 曲线颜色
            },
            areaStyle: {
              // 曲线下方的填充
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  // {
                  //   offset: 0,
                  //   color: "rgb(255, 158, 68)", // 0% 处的颜色
                  // },
                  // {
                  //   offset: 1,
                  //   color: "rgb(165, 82, 38)", // 100% 处的颜色
                  // },
                ],
                globalCoord: false, // 缺省为 false
              },
            },
          },
          {
            name: "每月订单",
            type: "line",
            data: this.orderData.counts, // 模拟数据
            smooth: true, // 平滑曲线
            symbol: "circle", // 数据点的图形
            symbolSize: 5, // 数据点大小
            itemStyle: {
              // 控制数据点的样式
              color: "transparent", // 数据点背景色透明
              borderColor: "#409eff", // 边框颜色
              borderWidth: 1, // 边框宽度
            },
            lineStyle: {
              width: 2, // 曲线宽度
              color: "#409eff", // 曲线颜色
            },
            areaStyle: {
              // 曲线下方的填充
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  // {
                  //   offset: 0,
                  //   color: "rgb(255, 158, 68)", // 0% 处的颜色
                  // },
                  // {
                  //   offset: 1,
                  //   color: "rgb(165, 82, 38)", // 100% 处的颜色
                  // },
                ],
                globalCoord: false, // 缺省为 false
              },
            },
          },
        ],
      };
      option && myChart.setOption(option);
    },
  },
};
</script>

<style lang="less" scoped>
.home {
  width: 100%;
  flex-grow: 1; /* 占据剩余空间 */
  &-statistics {
    padding: 30px;
    &-top {
      width: 100%;
      height: 124px;
      background: #fff;
      box-shadow: 0 1px 4px rgba(215, 219, 236, 0.37);
      border-radius: 5px;
      ::v-deep .el-row {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 10px 0;
        .el-col {
          border-right: 1px solid rgba(215, 219, 236, 0.37);
          height: 100%;
          .item {
            color: #f20;
            .el-row {
              .el-col {
                border: 0px;
                .statistics-item {
                  text-align: left;
                  padding-left: 4rem;
                  img {
                    width: 80px;
                    height: 80px;
                    line-height: 150px;
                    margin-top: 5px;
                    position: relative;
                    left: -30px;
                  }
                  &-num {
                    color: #000;
                  }
                  &-num:first-child {
                    font-size: 20px;
                    font-weight: 600;
                  }
                  &-num:nth-child(2) {
                    color: #ccc;
                    font-size: 14px;
                    margin: top 10px;
                  }
                  &-num:last-child {
                    margin-top: 20px;
                    color: #f20;
                  }
                }
              }
            }
          }
        }
        .el-col:last-child {
          border: 0px;
        }
      }
    }
    &-echarts {
      width: 100%;
      height: 400px;
      border-radius: 5px;
      background: #fff;
      box-shadow: 0 1px 4px rgba(215, 219, 236, 0.37);
      margin-top: 20px;
      padding-top: 20px;
    }
    &-list {
      width: 100%;
      height: 280px;
      margin-top: 20px;
      ::v-deep .el-row {
        .el-col {
          > div {
            background: #fff;
            border-radius: 5px;
            box-shadow: 0 1px 4px rgba(215, 219, 236, 0.37);
            height: 280px;
          }
          .home-statistics-list-left {
            text-align: left;
            &-title {
              text-align: center;
              height: 40px;
              line-height: 40px;
              font-size: 16px;
              font-weight: 400;
              font-family: system-ui;
            }
          }
          .home-statistics-list-right {
            text-align: left;
          }
        }
      }
    }
  }
}
::v-deep .cell {
  text-align: center;
  overflow: inherit !important;
}
.paid {
  padding: 4px 10px;
  border: 1px solid #27930c;
  border-radius: 5px;
  color: #27930c;
}
.paid {
  padding: 4px 10px;
  border: 1px solid #27930c;
  border-radius: 5px;
  color: #27930c;
}
.unpaid {
  padding: 4px 10px;
  border: 1px solid #abb51d;
  border-radius: 5px;
  color: #abb51d;
}
.failed {
  padding: 4px 10px;
  border: 1px solid #f72f2f;
  border-radius: 5px;
  color: #f72f2f;
}
.undef {
  padding: 4px 10px;
  border: 1px solid #777474;
  border-radius: 5px;
  color: #777474;
}
.product-image {
  width: 22px;
  height: 22px;
  vertical-align: middle;
  margin-right: 15px;
}
.boxred {
  color: red;
}
.boxyellwo {
  color: #27930c;
}
</style>
