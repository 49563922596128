<template>
  <div id="threeView">
    <!-- 主要的容器 -->
    <div id="div2d" class="div2d"></div>
  </div>
</template>

<script>
export default {
  name: "MyComponent",
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      console.log(window.THING);
      // 初始化 ThingJS
      //   const ThingJsApi = THING.default || THING;
      //   // 使用 create 方法初始化
      //   if (typeof ThingJsApi.create === "function") {
      //     console.log("ThingJS initialized successfully.1");
      //     const engineInstance = ThingJsApi.create({
      //       container: "#div2d", // 确保容器 ID 正确
      //       renderer: {
      //         clearColor: 0xffffff,
      //       },
      //     });
      //     console.log(engineInstance);
      //   } else {
      //     console.error("ThingJS create method is not defined.");
      //   }
    },
  },
};
</script>

<style scoped>
#threeView {
  width: 100%;
  height: 100%;
}
</style>
