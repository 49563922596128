<template>
  <el-upload
    class="avatar-uploader"
    action="https://www.wy-data.top/upload/image"
    :show-file-list="false"
    :on-success="handleSuccess"
    :before-upload="beforeUpload"
    :loading="getData"
  >
    <img v-if="imageUrl" :src="imageUrl" class="avatar" />
    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
  </el-upload>
</template>

<script>
export default {
  name: "Upload",
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      imageUrl: this.value,
      getData: false,
    };
  },
  watch: {
    value(newVal) {
      this.imageUrl = newVal;
    },
  },
  methods: {
    handleSuccess(response, file) {
      console.log(response, file);
      this.getData = false; // 关闭 loading
      this.imageUrl = response.data.url; // 假设后端返回的是图片url
      this.$emit("input", this.imageUrl); // 更新父组件的值
    },
    beforeUpload(file) {
      this.getData = true; // 开启 loading
      //   const isJPG = file.type === "image/jpeg"; isJPG &&
      const isLt2M = file.size / 1024 / 1024 < 2;
      console.log(file);
      //   if (!isJPG) {
      //     this.$message.error("上传图片只能是 JPG 格式!");
      //   }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
  },
};
</script>

<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  border: 1px solid #c0c4cc;
  border-radius: 2px;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
