<template>
  <div>
    <!-- 模态对话框 -->
    <el-dialog
      :visible.sync="localDialogVisible"
      :title="dialogTitle"
      width="80%"
      @close="onClose"
    >
      <el-form :model="form" ref="form" :rules="rules">
        <el-form-item label="商品名称" :label-width="formLabelWidth">
          <el-input
            v-model="form.name"
            placeholder="请输入商品名称"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品价格" :label-width="formLabelWidth">
          <el-input-number
            v-model="form.price"
            controls-position="right"
            :min="0"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="商品内容" :label-width="formLabelWidth">
          <div class="editBox">
            <Toolbar
              style="border-bottom: 1px solid #ccc"
              :editor="editor"
              :defaultConfig="toolbarConfig"
              :mode="mode"
            />
            <Editor
              style="height: 300px; overflow-y: hidden"
              v-model="form.description"
              :config="editorConfig"
              :defaultConfig="editorConfig"
              :mode="mode"
              @onCreated="onCreated"
            />
          </div>
        </el-form-item>
        <el-form-item label="商品封面图" :label-width="formLabelWidth">
          <upload v-model="form.image" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="saveChanges">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import VueEditor from "vue2-editor";
import Upload from "@/components/Upload.vue"; // 确保路径正确
import { getShopAdd } from "@/api/api";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
export default {
  name: "GoodsEdit",
  components: {
    Editor,
    Toolbar,
    Upload,
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    dialogTitle: {
      type: String,
      default: "",
    },
    initialData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      localDialogVisible: false,
      form: {
        name: "请输入商品名称",
        price: 1,
        description: "请输入商品描述",
        image: "",
      },
      formLabelWidth: "120px",
      editor: null,
      html: "",
      toolbarConfig: {},
      editorConfig: {
        placeholder: "请输入商品内容...",
        MENU_CONF: {
          uploadImage: {
            // 上传图片的 URL 地址
            server: "/api/upload/image", // 替换为你的 API 地址
            // 上传前的钩子函数
            beforeUpload(file) {
              // 在这里可以做一些预处理，例如验证文件类型等
              return file;
            },
            // 成功后的回调函数
            success(res, file) {
              console.log("上传成功:", res, file);
              // 返回图片的 URL 地址
              return res.url;
            },
            // 失败后的回调函数
            fail(res, file) {
              console.error("上传失败:", res, file);
            },
            // 错误后的回调函数
            error(err, file) {
              console.error("上传错误:", err, file);
            },
            // 进度条的回调函数
            progress(progress, file) {
              console.log("上传进度:", progress, file);
            },
            // 其他配置项
            headers: {
              "X-CSRF-TOKEN": "your-token", // 替换为你的 CSRF 令牌
            },
            withCredentials: true, // 是否携带 cookie
            maxFileSize: 5 * 1024 * 1024, // 最大文件大小 2MB
            // 其他配置项
          },
          // 其他菜单配置
        },
      },
      mode: "default", // or 'simple'
      rules: {}, // 根据实际需求设置表单验证规则
    };
  },
  watch: {
    dialogVisible(newVal) {
      this.localDialogVisible = newVal;
      if (newVal) {
        console.log(this.initialData);
        if (this.initialData) {
          this.form = this.initialData;
        }
      }
    },
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },
    saveChanges() {
      // 发送保存请求到服务器
      const { id, name, price, description, image } = this.form;

      // 检查所有字段是否包含有效内容
      if (!name.trim()) {
        this.$message.error("商品名称不能为空");
        return;
      }

      if (isNaN(price) || price <= 0) {
        this.$message.error("商品价格必须为正数");
        return;
      }

      // 富文本编辑器的默认内容为 "<p><br></p>"
      if (description === "<p><br></p>") {
        this.$message.error("商品描述不能为空");
        return;
      }

      if (!image) {
        this.$message.error("请上传商品封面图");
        return;
      }
      const params = this.form;
      getShopAdd(params).then((res) => {
        console.log(res);
        if (res.code === 1) {
          if (!id) {
            this.$message.success("添加成功");
          } else {
            this.$message.success("修改成功");
          }
        } else {
          this.$message.error(res.message);
        }

        console.log(id);
      });
      // 如果所有字段都有效，则继续保存操作
      console.log(this.form);
      // 这里可以添加发送到服务器的逻辑
      // 例如：axios.post('/api/save-product', this.form)
      this.closeDialog();
    },
    closeDialog() {
      this.localDialogVisible = false;
      this.$emit("dialogVisibleChanged", false);
    },
    onClose() {
      this.localDialogVisible = false;
      this.$emit("dialogVisibleChanged", false);
    },
  },
};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="less" scoped>
/* 样式可以根据需要添加 */
.vue-editor {
  height: 200px; /* 自定义高度 */
}
::v-deep .el-dialog__wrapper {
  .el-dialog {
    margin-top: 5vh !important;
  }
}
::v-deep .el-form-item__content {
  line-height: normal;
}
.editBox {
  border: 1px solid #c0c4cc;
  border-radius: 2px;
  z-index: 9;
}
#editor-container {
  height: 200px;
}
</style>
