<template>
  <div class="top-bar" v-if="$route.name !== 'login'">
    <div class="header-layout" @click="toggleSidebar">
      <img class="layoutImg" :src="require('@/assets/img/layout.png')" alt="" />
    </div>
    <div class="header-user">
      <img :src="user.avatar ? user.avatar : img" alt="" />

      <el-dropdown @command="handleCommand" trigger="click">
        <span>{{ user.name }}</span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="loginout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopBar",
  data() {
    return {
      user: {
        id: 1,
        name: "admin",
      },
      img: require("@/assets/img/admin.png"),
    };
  },
  methods: {
    toggleSidebar() {
      this.$emit("toggle-sidebar", !this.isSidebarCollapsed); // 发出事件
    },
    handleCommand(command) {
      console.log(123, command);
      localStorage.removeItem("token");
      sessionStorage.removeItem("token");
      this.$message({
        message: "退出成功",
        type: "success",
      });
      this.$router.push({ path: "/login" });
    },
  },
};
</script>

<style lang="less" scoped>
.top-bar {
  width: 100%;
  height: 50px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between; // 让元素分布在两端
  padding: 0 20px; // 添加一些内边距以防止元素紧贴边缘
  .header-layout {
    cursor: pointer; // 鼠标指针变成手型
    .layoutImg {
      width: 30px;
      height: 30px;
      border: 0;
      border-radius: 0;
    }
  }
  .header-user {
    width: 15%;
    text-align: right;
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 1px solid #ccc;
      vertical-align: middle;
      cursor: pointer;
    }
    span {
      vertical-align: middle;
      margin-left: 15px;
      cursor: pointer;
      color: #00aaff;
    }
  }
}
</style>
