<template>
  <div id="product-management">
    <el-row :gutter="20" class="header-actions">
      <el-col :span="16">
        <el-button type="info" @click="refreshData"
          ><i class="el-icon-refresh"></i
        ></el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          添加</el-button
        >
        <!-- <el-button type="success" @click="handleEdit">编辑</el-button> -->
        <el-button
          type="danger"
          icon="el-icon-delete"
          :disabled="this.selectedRows.length === 0"
          @click="handleDeleteSelected"
        >
          删除</el-button
        >
      </el-col>
      <el-col :span="8" class="search-export">
        <el-input
          placeholder="搜索商品"
          v-model="searchInput"
          class="search-input"
          @input="handleSearch"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="handleSearch"
          ></el-button>
        </el-input>
        <!-- <el-button type="primary" @click="exportToPDF" class="export-btn"
          >导出为PDF</el-button
        > -->
      </el-col>
    </el-row>

    <el-table
      :data="filteredProducts"
      style="width: 100%"
      border
      v-loading="loading"
      :cell-style="{ textAlign: 'center' }"
      :header-cell-style="{ textAlign: 'center' }"
      class="product-table"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="id" label="ID" width="80"></el-table-column>
      <el-table-column
        prop="name"
        label="商品名称"
        width="180"
      ></el-table-column>
      <el-table-column prop="description" label="商品介绍">
        <template slot-scope="scope">
          <div v-html="scope.row.description"></div>
        </template>
      </el-table-column>
      <el-table-column prop="image" width="180" label="商品图片">
        <template slot-scope="scope">
          <img
            :src="scope.row.image"
            alt="Product Image"
            style="max-width: 100px"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="price"
        label="商品价格"
        width="120"
      ></el-table-column>
      <el-table-column
        prop="createdAt"
        label="创建时间"
        width="180"
        :formatter="formatCreatedAt"
      ></el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button
            size="small"
            type="primary"
            icon="el-icon-edit"
            @click="handleEdit(scope.$index, scope.row)"
          >
          </el-button>
          <el-button
            size="small"
            type="danger"
            icon="el-icon-delete"
            @click="handleDelete(scope.$index, scope.row)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <goods-edit
      :dialog-visible="dialogVisible"
      :dialog-title="dialogTitle"
      :initial-data="currentProduct"
      @dialogVisibleChanged="DialogVisibility"
    ></goods-edit>
    <Pagination
      :pagination="pagination"
      @current-change="onCurrentPageChange"
    />
  </div>
</template>

<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
import GoodsEdit from "@/components/dialog/GoodsEdit.vue";
import Pagination from "@/components/Pagination.vue";
import { getShopList, getShopDelete } from "@/api/api";
export default {
  name: "ShopList",
  components: { GoodsEdit, Pagination },
  data() {
    return {
      loading: true,
      searchInput: "",
      selectedRows: [],
      dialogVisible: false,
      dialogTitle: "",
      currentProduct: {},
      pagination: {
        total: 120, // 总共的数据项数量
        page: 1, // 当前页码
        pageSize: 10, // 每页显示的数据项数量
      },
      products: [
        // 示例数据
      ],
    };
  },
  computed: {
    filteredProducts() {
      return this.products;
    },
  },
  created() {
    const data = {
      page: 1,
      pageSize: 7,
    };
    this.getShopData(data);
  },
  methods: {
    handleSearch() {
      if (this.searchInput) {
        console.log(this.searchInput);
        const params = {
          name: this.searchInput,
          page: 1,
          pageSize: 7,
        };
        this.loading = true;
        getShopList(params).then((res) => {
          console.log(res);
          const that = this;
          setTimeout(function () {
            that.products = res.data.data;
            that.pagination = res.data.pagination;
            that.loading = false;
          }, 2000);
        });
      } else {
        this.$message.error("请输入内容");
      }
    },
    formatCreatedAt(row, column, cellValue) {
      // 使用全局方法格式化日期
      return this.$formatDate(cellValue);
    },
    getShopData(data) {
      const { page, pageSize } = data;
      const params = {
        page,
        pageSize,
      };
      getShopList(params).then((res) => {
        console.log(res);
        const that = this;
        setTimeout(function () {
          that.products = res.data.data;
          that.loading = false;
        }, 2000);
        this.pagination = res.data.pagination;
      });
    },
    onCurrentPageChange(newPage) {
      const data = {
        page: newPage,
        pageSize: 7,
      };
      this.loading = true;
      this.getShopData(data);
      // 在这里你可以更新状态，重新请求数据等
      this.pagination.page = newPage; // 更新当前页码
      // 可能需要在这里发送请求获取新的数据
    },
    DialogVisibility(value) {
      this.refreshData();
      this.dialogVisible = value;
    },
    handleAdd() {
      this.dialogTitle = "添加商品";
      this.currentProduct = {
        name: "",
        price: 0,
        description: "",
        image: "",
      };
      this.dialogVisible = true;
    },
    handleEdit(index, row) {
      this.dialogTitle = "编辑商品";
      this.currentProduct = row;
      this.dialogVisible = true;
    },
    handleDelete(index, row) {
      const params = {
        id: row.id,
      };
      getShopDelete(params).then((res) => {
        if (res.code === 1) {
          this.$message.success("数据删除成功");
          this.refreshData();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    refreshData() {
      // 刷新数据
      const data = {
        page: this.pagination.page,
        pageSize: 7,
      };
      this.loading = true;
      this.getShopData(data);
    },
    handleDeleteSelected() {
      console.log(this.selectedRows);
      if (this.selectedRows.length > 0) {
        this.selectedRows.forEach((row) => {
          console.log(row.id);
        });
      } else {
        console.log(2);
      }

      // this.selectedRows.forEach((row) => {
      //   const index = this.products.findIndex(
      //     (product) => product.id === row.id
      //   );
      //   // if (index !== -1) {
      //   //   this.products.splice(index, 1);
      //   // }
      //   console.log(index);
      // });
      // this.selectedRows = [];
    },
    handleSelectionChange(val) {
      this.selectedRows = val;
    },
    exportToPDF() {
      /* eslint-disable new-cap */
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });

      const columns = [
        { title: "选择", dataKey: "selected" },
        { title: "ID", dataKey: "id" },
        { title: "商品名称", dataKey: "name" },
        { title: "商品介绍", dataKey: "description" },
        { title: "商品图片", dataKey: "image" },
        { title: "商品价格", dataKey: "price" },
        { title: "创建时间", dataKey: "created_at" },
      ];

      // 自定义渲染函数来处理图片列
      const renderCell = (data) => {
        if (data.column.dataKey === "image") {
          return `<img src="${data.cell.raw}" style="max-width: 50px; max-height: 50px;">`;
        }
        return data.cell.raw;
      };

      doc.autoTable({
        head: [columns.map((column) => column.title)],
        body: this.filteredProducts.map((row) => {
          return columns.map((column) => ({
            raw:
              column.dataKey === "selected"
                ? this.selectedRows.includes(row)
                  ? "√"
                  : ""
                : row[column.dataKey],
            content: renderCell({
              cell: {
                raw:
                  column.dataKey === "selected"
                    ? this.selectedRows.includes(row)
                      ? "√"
                      : ""
                    : row[column.dataKey],
              },
              column,
            }),
          }));
        }),

        didDrawPage: function (data) {
          doc.setFontSize(10);
          doc.setTextColor(150);
          doc.text(
            "Page " + doc.internal.getNumberOfPages(),
            data.settings.margin.right,
            data.settings.margin.top,
            { align: "right" }
          );
        },

        styles: {
          fontSize: 9,
          overflow: "linebreak",
          valign: "middle",
          halign: "center",
          cellWidth: "wrap",
        },
        theme: "grid",
        margin: { top: 40 },
      });

      // 设置默认字体为支持中文的字体
      doc.setFont("helvetica"); // 如果有支持中文的字体，可以在这里替换
      doc.save("products.pdf");
    },
  },
};
</script>

<style scoped>
#product-management {
  padding: 20px;
  text-align: left;
}

.header-actions {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-export {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.search-input {
  margin-right: 10px;
  width: 300px;
}

.export-btn {
  height: 38px;
  line-height: 38px;
}

.product-table {
  margin-top: 20px;
}

.product-table img {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
}

/* 额外美化 */
.product-table .el-table__body tr:hover > td {
  background-color: #f5f7fa !important;
}

.product-table .el-table__header th {
  background-color: #eef1f6;
  color: #4a4a4a;
}
.export-btn {
  line-height: 16px;
}
.product-table .el-button--mini {
  padding: 5px 10px;
}

.product-table .el-button--mini + .el-button--mini {
  margin-left: 5px;
}
</style>
