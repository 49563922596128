<template>
  <div class="login-box">
    <div class="login-box-title">后台管理系统</div>
    <div class="login-box-form">
      <div class="login-box-form-username">
        <div class="login-box-form-username-label">用户名：</div>
        <div class="login-box-form-username-input">
          <el-input
            v-model="form.username"
            placeholder="请输入用户名"
          ></el-input>
        </div>
      </div>
      <div class="login-box-form-password login-box-form-username">
        <div
          class="login-box-form-password-label login-box-form-username-label"
        >
          密&nbsp;&nbsp;&nbsp;&nbsp;码：
        </div>
        <div
          class="login-box-form-password-input login-box-form-username-input"
        >
          <el-input
            v-model="form.password"
            placeholder="请输入密码"
            show-password
          ></el-input>
        </div>
      </div>
      <div class="login-box-form-btn" @click="loginPut">登录</div>
    </div>
  </div>
</template>

<script>
import { getLogin } from "@/api/api";

export default {
  name: "Login",

  // 初始化数据
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      ipAddress: "",
    };
  },

  // 在实例初始化之后立即调用
  beforeCreate() {
    console.log("beforeCreate - 数据还未初始化");
  },

  // 在实例创建完成后被立即调用
  created() {},

  // 在挂载开始之前被调用
  beforeMount() {
    console.log("beforeMount - 即将挂载到 DOM");
  },

  // 在实例创建完成后被立即调用
  async mounted() {
    // try {
    //   this.ipAddress = await this.fetchIPAddress();
    // } catch (error) {
    //   console.error("Failed to fetch IP address:", error);
    // }
  },

  // 数据更新之前
  beforeUpdate() {
    console.log("beforeUpdate - 数据即将更新");
  },

  // 数据更新之后
  updated() {
    console.log("updated - 数据已经更新，DOM 已经重绘");
  },

  // 触发实例销毁之前
  beforeDestroy() {
    console.log("beforeDestroy - 实例即将销毁");
  },

  // Vue 实例销毁后调用
  destroyed() {
    console.log("destroyed - 实例已经被销毁");
  },

  methods: {
    // async fetchIPAddress() {
    //   const response = await axios.get("https://api64.ipify.org?format=json");
    //   console.log(response);
    //   return response.data.ip;
    // },
    loginPut() {
      const { username, password } = this.form;
      if (!username) {
        console.log(123);
        this.$message.error("请输入用户名");
      } else if (!password) {
        console.log(123);
        this.$message.error("请输入密码");
      } else {
        const data = {
          username,
          password,
        };
        getLogin(data).then((res) => {
          console.log(res);
          if (!res.data) {
            this.$message.error(res.message);
          } else {
            this.$message({
              message: "登录成功，正在跳转请稍后",
              type: "success",
            });
            // 获取重定向目标

            // 重定向到目标页面
            setTimeout(() => {
              this.$router.push({ path: "/home" });
            }, 3000);
          }
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
.login-box {
  width: 100%;
  height: 100%;
  background: linear-gradient(93deg, #fff, #000);
  position: relative;
  &-title {
    color: #faebd7;
    position: absolute;
    left: 50%;
    top: 20%;
    transform: translate(-50%, -50%);
    font-size: 40px;
    font-weight: 600;
  }
  &-title::after {
    content: "后台管理系统";
    color: #000;
    position: absolute;
    left: 0;
    transform: translate(-19px, 5px) scaleY(0.7) skew(46deg);
    z-index: -1;
    mask: linear-gradient(transparent;#000);
  }
  &-form {
    padding: 40px 50px;
    background: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    box-shadow: 0 1px 4px #ccc;
    &-username {
      display: flex;
      &-title {
        line-height: 26px;
      }
      &-input {
        min-width: 150px;
        max-width: 300px;
        outline: none;
        resize: vertical;
        background-color: #fff;
        font-family: Arial, sans-serif;
        font-size: 14px;
        color: #aaa;
        transition: color 0.3s;
        border-bottom: 1px solid #cccccc40;
        margin-left: 6px;
        box-sizing: border-box;
        text-align: left;
        padding-bottom: 0px;
        line-height: 26px;
        ::v-deep .el-input {
          .el-input__inner {
            border: 0px;
            padding: 0 5px;
            height: 26px;
            line-height: 26px;
            border-radius: 0;
          }
        }
      }
    }
    &-password {
      margin-top: 30px;
    }
    &-btn {
      display: inline-block;
      padding: 5px 110px;
      background: #6a94f1e8;
      border-radius: 50px;
      color: #fff;
      font-size: 16px;
      margin-top: 30px;
      cursor: pointer;
    }
  }
}
::v-deep .el-input__icon {
  line-height: 24px;
}
</style>
