// apiService.js
import axios from './axios';
import {
  saveToken
} from './auth';

// 登录接口
export async function getLogin(data) {
  try {
    const response = await axios.post('/admin/login/login', data);
    // 存储 token
    saveToken(response.data.token);
    return response;
  } catch (error) {
    console.error('Login failed:', error);
    throw error;
  }
}

// 商品列表
export function getShopList(params) {
  return axios.get('/admin/shops/list', {
      params: params
    })
    .then(response => {
      return response;
    });
}

// 添加或修改商品
export function getShopAdd(params) {
  return axios.post('/admin/shops/add', {
      params
    })
    .then(response => {
      return response;
    });
}

// 删除商品
export function getShopDelete(params) {
  return axios.get('/admin/shops/delete', {
      params
    })
    .then(response => {
      return response;
    });
}

// 获取管理员列表
export function getUserList(params) {
  return axios.get('/admin/login/list', {
      params
    })
    .then(response => {
      return response;
    });
}

// 添加管理员
export function getUserAdd(params) {
  return axios.post('/admin/login/add', params)
    .then(response => {
      return response;
    });
}

// 删除商品
export function getUserDelete(params) {
  return axios.get('/admin/shops/delete', {
      params
    })
    .then(response => {
      return response;
    });
}

// 订单列表
export function getOrdersList(params) {
  return axios.get('/admin/orders/list', {
      params
    })
    .then(response => {
      return response;
    });
}

// 订单统计
export function getOrdersStic(params) {
  return axios.get('/admin/orders/statistics', {
      params: params
    })
    .then(response => {
      return response;
    });
}
// 地图获取经纬度
// eslint-disable-next-line no-unused-vars
export function getLocationFromAddress(params) {
  return axios.post('/admin/persons/getLocationFromAddress',
      params
    )
    .then(response => {
      return response;
    });
}

// 地图地址
export function getAddressFromLocation(params) {
  return axios.post('/admin/persons/getAddressFromLocation',
      params
    )
    .then(response => {
      return response;
    });
}

// 人员添加
export function getPersonsAdd(params) {
  return axios.post('/admin/persons/add',
      params
    )
    .then(response => {
      return response;
    });
}
// 人员列表
export function getPersonsList(params) {
  return axios.get('/admin/persons/list', {
      params
    })
    .then(response => {
      return response;
    });
}
export function postAdminData(data) {
  return axios.post('/admin', data);
}
