<template>
  <div id="product-management">
    <el-row :gutter="20" class="header-actions">
      <el-col :span="16">
        <el-button type="info" @click="refreshData"
          ><i class="el-icon-refresh"></i
        ></el-button>
      </el-col>
      <el-col :span="8" class="search-export">
        <el-input
          placeholder="搜索商品"
          v-model="searchInput"
          class="search-input"
        >
          <el-button slot="append" icon="el-icon-search"></el-button>
        </el-input>
        <!-- <el-button type="primary" @click="exportToPDF" class="export-btn"
          >导出为PDF</el-button
        > -->
      </el-col>
    </el-row>

    <el-table
      :data="filteredProducts"
      style="width: 100%"
      border
      :cell-style="{ textAlign: 'center' }"
      :header-cell-style="{ textAlign: 'center' }"
      class="product-table"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="id" label="订单编号" width="80"></el-table-column>
      <el-table-column
        prop="username"
        label="用户名称"
        width="180"
      ></el-table-column>
      <el-table-column prop="title" label="商品名称"></el-table-column>
      <el-table-column
        prop="price"
        label="订单价格"
        width="120"
      ></el-table-column>
      <el-table-column
        prop="createdAt"
        label="创建时间"
        width="180"
        :formatter="formatCreatedAt"
      ></el-table-column>
    </el-table>
    <Pagination
      :pagination="pagination"
      @current-change="onCurrentPageChange"
    />
  </div>
</template>

<script>
import { getOrdersList } from "@/api/api";
import Pagination from "@/components/Pagination.vue";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  name: "OrderGoods",
  components: {
    Pagination,
  },
  data() {
    return {
      searchInput: "",
      selectedRows: [],
      products: [],
      pagination: {
        total: 120, // 总共的数据项数量
        page: 1, // 当前页码
        pageSize: 10, // 每页显示的数据项数量
      },
    };
  },
  computed: {
    filteredProducts() {
      return this.products;
    },
  },
  created() {
    const data = {
      page: 1,
      pageSize: 14,
    };
    this.getData(data);
  },
  methods: {
    onCurrentPageChange(newPage) {
      const data = {
        page: newPage,
        pageSize: 14,
      };
      this.getData(data);
      // 在这里你可以更新状态，重新请求数据等
      this.pagination.page = newPage; // 更新当前页码
      // 可能需要在这里发送请求获取新的数据
    },
    getData(data) {
      getOrdersList(data).then((res) => {
        console.log(res);
        this.products = res.data.data.data;
        this.pagination = res.data.pagination;
      });
    },
    formatCreatedAt(row, column, cellValue) {
      // 使用全局方法格式化日期
      return this.$formatDate(cellValue);
    },
    handleDelete(index, row) {
      console.log(`删除商品: ${row.name}`);
      this.products.splice(index, 1);
    },
    // 刷新数据
    refreshData() {
      const data = {
        page: this.pagination.page,
        pageSize: 14,
      };
      this.products = [];
      this.getData(data);
    },
    handleDeleteSelected() {
      this.selectedRows.forEach((row) => {
        const index = this.products.findIndex(
          (product) => product.id === row.id
        );
        if (index !== -1) {
          this.products.splice(index, 1);
        }
      });
      this.selectedRows = [];
    },
    handleSelectionChange(val) {
      this.selectedRows = val;
    },
    exportToPDF() {
      // 定义文档内容
      const docDefinition = {
        content: [
          {
            text: "这是一个测试文本：商品名称：商品A 描述：这是商品 A 的描述。",
            fontSize: 12,
          },
        ],
      };

      // 生成 PDF 文件
      const pdfDoc = pdfMake.createPdf(docDefinition);
      pdfDoc.open();
      // 或者保存 PDF 文件
      // pdfDoc.download('sample.pdf');
    },
  },
};
</script>

<style scoped>
#product-management {
  padding: 20px;
  text-align: left;
}

.header-actions {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-export {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.search-input {
  margin-right: 10px;
  width: 300px;
}

.export-btn {
  height: 38px;
  line-height: 38px;
}

.product-table {
  margin-top: 20px;
}

.product-table img {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
}

/* 额外美化 */
.product-table .el-table__body tr:hover > td {
  background-color: #f5f7fa !important;
}

.product-table .el-table__header th {
  background-color: #eef1f6;
  color: #4a4a4a;
}
.export-btn {
  line-height: 16px;
}
.product-table .el-button--mini {
  padding: 5px 10px;
}

.product-table .el-button--mini + .el-button--mini {
  margin-left: 5px;
}
</style>
