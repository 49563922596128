<template>
  <div class="webGis">
    <WebGis />
  </div>
</template>

<script>
import WebGis from "@/components/threeWeb.vue";

export default {
  components: {
    WebGis,
  },
};
</script>
<style lang="less" scoped>
.webGis {
  width: 100%;
  flex-grow: 1; /* 占据剩余空间 */
}
</style>
