// axiosInstance.js
import axios from 'axios';
import Vue from 'vue';
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import {
  getToken,
} from './auth'; // 假设你有一个auth模块来处理token
// const API_BASE_URL = 'http://localhost:3000'
const API_BASE_URL = 'https://www.wy-data.top'
const config = {
  baseURL: API_BASE_URL,
  timeout: 50000,
  headers: {
    'Content-Type': 'application/json'
  }
};

const _axios = axios.create(config);

// 请求拦截器
_axios.interceptors.request.use(
  function (config) {
    // 检查是否为login接口
    if (config.url !== '/login') {
      // 获取本地缓存中的token
      const token = getToken();
      if (token) {
        config.headers.token = `${token}`;
      }
    }
    return config;
  },
  function (error) {
    console.log(error);
    return Promise.reject(error);
  }
);

// 响应拦截器
_axios.interceptors.response.use(
  function (response) {
    if (response.status !== 200) {
      // 返回一个带有Error对象的Promise
      return Promise.reject(new Error('Request failed with status code ' + response.status));
    }
    return response.data;
  },
  function (error) {
    console.log('err:', error);
    return Promise.reject(error);
  }
);

// 将 axios 实例注册为 Vue 插件
const Plugin = {
  install(Vue) {
    Vue.axios = _axios;
    window.axios = _axios;
    Object.defineProperties(Vue.prototype, {
      axios: {
        get() {
          return _axios;
        }
      },
      $axios: {
        get() {
          return _axios;
        }
      }
    });
  }
};

Vue.use(Plugin);

export default _axios;
