<template>
  <el-pagination
    background
    layout="prev, pager, next"
    :total="total"
    :current-page="currentPage"
    :page-size="pageSize"
    @current-change="handleCurrentChange"
  >
  </el-pagination>
</template>

<script>
export default {
  props: {
    pagination: {
      type: Object,
      required: true,
      validator: function (value) {
        return (
          value.total !== undefined &&
          value.page !== undefined &&
          value.pageSize !== undefined
        );
      },
    },
  },
  computed: {
    total() {
      return this.pagination.total;
    },
    currentPage() {
      return this.pagination.page;
    },
    pageSize() {
      return this.pagination.pageSize;
    },
  },
  methods: {
    handleCurrentChange(page) {
      this.$emit("current-change", page);
    },
  },
};
</script>
