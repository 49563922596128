import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './api/axios';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/styles/login-styles.less';
import dateFormatPlugin from './utils/dateFormatPlugin';
// import VueAMap from 'vue-amap';

// Vue.use(VueAMap);
// VueAMap.initAMapApiLoader({
//   key: 'bccb69725b9d1f111b1061c2c05c9249', // 您的 API Key
//   plugin: [
//     'AMap.Autocomplete',
//     'AMap.PlaceSearch',
//     'AMap.Scale',
//     'AMap.OverView',
//     'AMap.ToolBar',
//     'AMap.MapType',
//     'AMap.PolyEditor',
//     'AMap.CircleEditor'
//   ], // 插件集合
//   uiVersion: "1.0.11", // UI 版本
//   v: "1.4.15", // API 版本
// });

Vue.use(ElementUI);
Vue.use(dateFormatPlugin);
Vue.config.productionTip = false;

// 加载 AMap 并挂载到 Vue 原型上
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
