<template>
  <div class="addBox">
    <el-form :model="form" ref="form">
      <el-form-item label="姓名" :label-width="formLabelWidth">
        <el-input
          v-model="form.name"
          placeholder="请输入姓名"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="年龄" :label-width="formLabelWidth">
        <el-input
          v-model="form.age"
          placeholder="请输入年龄"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="性别" :label-width="formLabelWidth">
        <el-select v-model="form.sex" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属人群" :label-width="formLabelWidth">
        <el-select v-model="form.groupId" placeholder="请选择">
          <el-option
            v-for="item in options1"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="手机号" :label-width="formLabelWidth">
        <el-input
          v-model="form.phoneNumber"
          placeholder="请输入手机号"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="身份证号" :label-width="formLabelWidth">
        <el-input
          v-model="form.idCard"
          placeholder="请输入身份证号"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="人员介绍" :label-width="formLabelWidth">
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入内容"
          v-model="form.introduction"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="地址" :label-width="formLabelWidth">
        <div class="lanBox">
          <el-input
            v-model="form.address"
            placeholder="请输入地址"
            autocomplete="off"
          ></el-input>
          <div class="mapBox">
            <el-button type="primary" @click="showMap">点击显示地图</el-button>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="人员照片" :label-width="formLabelWidth">
        <upload v-model="form.image" />
      </el-form-item>
    </el-form>
    <el-dialog :visible.sync="mapDialogVisible" title="选择位置" width="80%">
      <div id="container" ref="mapContainer"></div>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="upformData()" :loading="getData"
        >确 定</el-button
      >
    </el-dialog>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="saveChanges" :loading="getData"
        >确 定</el-button
      >
    </span>
  </div>
</template>

<script>
import Upload from "@/components/Upload.vue"; // 确保路径正确
import { load } from "@amap/amap-jsapi-loader";
import {
  getAddressFromLocation,
  getLocationFromAddress,
  getPersonsAdd,
} from "@/api/api";
export default {
  name: "AMapAdd",
  components: {
    Upload,
  },
  props: {
    dialogTitle: {
      type: String,
      default: "",
    },
    initialData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      form: {
        name: "",
        groupId: "",
        image: "",
        lng: "",
        lat: "",
        introduction: "",
        phoneNumber: "",
        idCard: "",
        age: "",
        sex: "",
        address: "",
      },
      options: [
        { value: "0", label: "男" },
        { value: "1", label: "女" },
      ],
      options1: [
        { value: "1", label: "测试人群1" },
        { value: "2", label: "测试人群2" },
        { value: "3", label: "测试人群3" },
      ],
      value: "",
      select: "",
      select1: "",
      formLabelWidth: "120px",
      mapDialogVisible: false,
      map: null, // 地图对象
      newData: {},
      getData: false, // 检查是否获取完成地址
    };
  },
  methods: {
    showMap() {
      this.mapDialogVisible = true;
      this.initMap();
    },
    upformData() {
      console.log(this.newData);
      this.form.address = this.newData.address;
      this.mapDialogVisible = false;
    },
    close() {
      this.mapDialogVisible = false;
    },
    async getAddressFromLatLngs(latLng) {
      this.getData = true;
      console.log(latLng);
      const params = {
        lng: latLng.lng,
        lat: latLng.lat,
      };
      await getAddressFromLocation(params).then((res) => {
        console.log(res);
        if (res.code === 1) {
          console.log(1);
          this.newData = res.data.data;
          this.getData = false;
        } else {
          this.$message.error("地址获取失败 请稍后重试！");
        }
      });
    },
    async initMap() {
      try {
        await load({
          key: "bccb69725b9d1f111b1061c2c05c9249", // 替换为你的高德地图API密钥
          version: "2.0",
          plugins: ["AMap.Marker", "AMap.Polygon", "AMap.Geocoder"],
        });
        console.log("高德地图 API 加载成功");
        console.log(this.$refs.mapContainer);
        this.map = new AMap.Map(this.$refs.mapContainer, {
          center: [117.2757034, 31.8632545],
          viewMode: "2D",
          pitch: 60,
          layers: [
            // 高德默认标准图层
            new AMap.TileLayer.Satellite(),
          ],
          zoom: 17,
        });
        this.map.on("click", (e) => {
          // 清除之前的标记
          if (this.marker) {
            this.marker.setMap(null);
          }

          // 创建新的标记
          this.marker = new AMap.Marker({
            position: e.lnglat,
            draggable: true,
            cursor: "move",
            raiseOnDrag: true,
            offset: new AMap.Pixel(0, 0),
          });
          this.marker.setMap(this.map);

          // 设置坐标值
          this.marker.on("dragend", (ev) => {
            this.getAddressFromLatLngs(ev.lnglat);
          });
          this.getAddressFromLatLngs(e.lnglat);
          //   this.mapDialogVisible = false;
        });
      } catch (error) {
        console.error("高德地图 API 加载失败:", error);
      }
    },
    saveChanges() {
      // 保存数据逻辑
      console.log(this.form);
      this.getData = true;
      const {
        name,
        groupId,
        image,
        introduction,
        phoneNumber,
        idCard,
        age,
        sex,
        address,
      } = this.form;
      if (!name) {
        return this.$message.error("请输入姓名");
      }
      if (!groupId) {
        return this.$message.error("请选择所属人群");
      }
      if (!image) {
        return this.$message.error("请上传人员照片");
      }
      if (!introduction) {
        return this.$message.error("请输入人员介绍");
      }
      if (!phoneNumber) {
        return this.$message.error("请输入人员手机号");
      }
      if (!idCard) {
        return this.$message.error("请输入人员身份证号");
      }
      if (!age) {
        return this.$message.error("请输入人员年龄");
      }
      if (!sex) {
        return this.$message.error("请选择人员性别");
      }
      if (!address) {
        return this.$message.error("请输入地址");
      }
      const params = {
        address: this.form.address,
      };
      getLocationFromAddress(params).then((res) => {
        if (res.code === 1) {
          this.form.lng = res.data.data.lng;
          this.form.lat = res.data.data.lat;
          const params = this.form;
          getPersonsAdd(params).then((res) => {
            if (res.code === 1) {
              this.getData = false;
              return this.$message.success("添加成功");
            } else {
              return this.$message.error("添加失败！");
            }
          });
        } else {
          return this.$message.error("经纬度获取失败！");
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.addBox {
  padding: 20px;
  ::v-deep .el-form-item__content {
    .avatar-uploader {
      text-align: left;
      .el-upload {
        background: #fff;
      }
    }
    .el-select {
      width: 100%;
    }
    .lanBox {
      display: flex;
      .el-input {
        flex: 1;
      }
      .mapBox {
        width: 150px;
        margin-left: 15px;
      }
    }
  }
}
#container {
  width: 100%;
  height: 600px;
  margin-bottom: 15px;
}
</style>
