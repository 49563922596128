<template>
  <div id="setupView">
    <el-menu
      :default-active="defaultActive"
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose"
      mode="vertical"
    >
      <el-submenu
        v-for="(item, index) in menuItems"
        :key="index"
        :index="item.index"
        :open="item.expanded"
      >
        <template slot="title">
          <div class="menu-title">
            <div class="left">
              <i
                :class="
                  item.expanded ? 'el-icon-arrow-down' : 'el-icon-arrow-right'
                "
              ></i>
              <i :class="item.icon"></i>
              <span>{{ item.title }}</span>
            </div>
            <div class="right">
              <div @mousedown.stop>
                <el-switch
                  v-model="item.enabled"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  @change="onSwitchChange(item)"
                ></el-switch>
              </div>
              <el-button size="mini" type="warning" @click.stop="editItem(item)"
                >编辑</el-button
              >
              <el-button
                size="mini"
                type="danger"
                @click.stop="deleteItem(item)"
                >删除</el-button
              >
            </div>
          </div>
        </template>
        <el-menu-item
          v-for="subItem in item.children"
          :key="subItem.index"
          :index="subItem.index"
        >
          <div class="menu-title">
            <div class="left">
              <i :class="subItem.icon"></i>
              <span>{{ subItem.title }}</span>
            </div>
            <div class="right">
              <el-switch
                v-model="subItem.enabled"
                active-color="#13ce66"
                inactive-color="#ff4949"
                @change="onSwitchChange(subItem)"
              ></el-switch>
              <el-button
                size="mini"
                type="warning"
                @click.stop="editItem(subItem)"
                >编辑</el-button
              >
              <el-button
                size="mini"
                type="danger"
                @click.stop="deleteItem(subItem)"
                >删除</el-button
              >
            </div>
          </div>
        </el-menu-item>
      </el-submenu>
      <el-menu-item
        v-for="item in menuItems.filter((item) => !item.children)"
        :key="item.index"
        :index="item.index"
        :disabled="item.disabled"
      >
        <i :class="item.icon"></i>
        <span slot="title">{{ item.title }}</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: "setupView",
  data() {
    return {
      defaultActive: "1",
      menuItems: [
        {
          title: "后端首页",
          icon: "el-icon-setting",
          index: "1",
          expanded: false,
          enabled: true,
          children: [
            {
              title: "配置",
              icon: "el-icon-setting",
              index: "1-1",
              enabled: true,
              children: [],
            },
            {
              title: "支付设置",
              icon: "el-icon-setting",
              index: "1-2",
              enabled: true,
              children: [],
            },
            {
              title: "物流设置",
              icon: "el-icon-setting",
              index: "1-3",
              enabled: true,
              children: [],
            },
            {
              title: "菜单权限管理",
              icon: "el-icon-setting",
              index: "1-4",
              enabled: true,
              children: [],
            },
          ],
        },
        {
          title: "主控台",
          icon: "el-icon-s-home",
          index: "2",
          children: [],
          enabled: true,
        },
        {
          title: "系统设置",
          icon: "el-icon-setting",
          index: "3",
          enabled: true,
          expanded: false,
          children: [
            {
              title: "基础设置",
              icon: "el-icon-setting",
              index: "3-1",
              enabled: true,
              children: [],
            },
          ],
        },
      ],
    };
  },
  methods: {
    handleOpen(key, keyPath) {
      const item = this.menuItems.find((item) => item.index === key);
      if (item) {
        item.expanded = true;
      }
    },
    handleClose(key, keyPath) {
      const item = this.menuItems.find((item) => item.index === key);
      if (item) {
        item.expanded = false;
      }
    },
    onSwitchChange(item) {
      console.log(
        `Switch state changed for item ${item.index}: ${item.enabled}`
      );
      // 您可以在这里添加其他逻辑，例如更新数据库
    },
    editItem(item) {
      console.log(`Editing item with index ${item.index}`);
      // 这里应该弹出一个表单或者进行其他编辑操作
    },
    deleteItem(item) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 删除逻辑
          const parent = this.menuItems.find((parentItem) =>
            parentItem.children.includes(item)
          );
          if (parent) {
            const index = parent.children.indexOf(item);
            parent.children.splice(index, 1);
          } else {
            const index = this.menuItems.indexOf(item);
            this.menuItems.splice(index, 1);
          }
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style scoped>
.el-menu-vertical-demo {
  height: 100%;
}

.menu-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left {
  display: flex;
  align-items: center;
}

.right {
  display: flex;
  align-items: center;
}
</style>
