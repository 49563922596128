<template>
  <div class="amapview">
    <div class="btn">
      <div
        v-for="(points, testPoint) in customPoints"
        :key="testPoint"
        @click="toggleMarkers(testPoint)"
      >
        <img :src="require('@/assets/img/author.png')" alt="" />
        <span>{{ testPoint }}</span>
      </div>
    </div>
    <div class="map" ref="mapContainer"></div>
  </div>
</template>

<script>
import { load } from "@amap/amap-jsapi-loader";
import { addMarker, createPolygon } from "@/utils/map"; // 导入 addMarker 方法
import { getPersonsList } from "@/api/api";
export default {
  name: "Map",
  data() {
    return {
      map: null,
      markers: {},
      customPoints: {
        测试人群1: [
          {
            id: 1,
            lat: 31.8632545,
            lng: 117.2757034,
            name: "张三",
            icon: require("@/assets/img/marker-14.png"),
          },
        ],
        测试人群2: [
          { id: 2, lat: 31.8532545, lng: 117.2857034, name: "位置D" },
        ],
        测试人群3: [
          { id: 3, lat: 31.8532545, lng: 117.2857034, name: "位置D" },
        ],
        // 可以添加更多的点位
      },
      center: [117.2757034, 31.8632545],
      polygonOptions: {
        strokeColor: "#0c93f3", // 边框颜色
        fillColor: "#000", // 填充颜色
        fillOpacity: 0.1, // 填充透明度
        strokeWeight: 2, // 边框宽度
        strokeStyle: "dashed", // 虚线
      },
    };
  },
  methods: {
    async initMap() {
      try {
        await load({
          key: "bccb69725b9d1f111b1061c2c05c9249", // 替换为你的高德地图API密钥
          version: "2.0",
          plugins: ["AMap.Marker", "AMap.Polygon"],
        });
        console.log("高德地图 API 加载成功");

        this.map = new AMap.Map(this.$refs.mapContainer, {
          center: this.center,
          viewMode: "2D",
          pitch: 60,
          layers: [
            // 高德默认标准图层
            new AMap.TileLayer.Satellite(),
          ],
          zoom: 9.5,
        });
        this.togglePolygon();
      } catch (error) {
        console.error("高德地图 API 加载失败:", error);
      }
    },
    addMarkers(testPoint) {
      console.log(this.customPoints[testPoint]);
      const params = {
        groupId: 1,
      };
      if (testPoint === "测试人群2") {
        params.groupId = 2;
      } else if (testPoint === "测试人群3") {
        params.groupId = 3;
      } else if (testPoint === "测试人群1") {
        params.groupId = 1;
      }
      getPersonsList(params).then((res) => {
        console.log(res);
        if (res.code === 1) {
          const points = res.data.data;
          points.forEach(async (point) => {
            const markerIconConfig = {
              image: require("@/assets/img/marker-14.png"),
              imageSize: new AMap.Size(60, 80), // 图标大小
              imageOffset: new AMap.Pixel(0, 0),
            };
            const marker = await addMarker(this.map, point, markerIconConfig);
            this.markers[testPoint] = this.markers[testPoint] || [];
            this.markers[testPoint].push(marker);
          });
          this.fitMarkersToBounds(testPoint);
        }
      });
    },
    removeMarkers(testPoint) {
      if (this.markers[testPoint]) {
        this.markers[testPoint].forEach((marker) => marker.setMap(null));
        this.markers[testPoint] = [];
      }
    },
    fitMarkersToBounds(testPoint) {
      if (
        this.map &&
        this.markers[testPoint] &&
        this.markers[testPoint].length > 0
      ) {
        const bounds = new AMap.Bounds();
        this.markers[testPoint].forEach((marker) => {
          bounds.extend(marker.getPosition());
        });
        this.map.setBounds(bounds);
      } else {
        this.map.setCenter(this.center);
      }
    },
    toggleMarkers(testPoint) {
      console.log(testPoint);
      if (!this.markers[testPoint] || this.markers[testPoint].length === 0) {
        // 如果当前没有标记点，则添加
        this.addMarkers(testPoint);
        const firstPoint = this.customPoints[testPoint][0];
        this.map.panTo([firstPoint.lng, firstPoint.lat]); // 平滑移动到第一个标记点
        this.map.setZoom(12);
      } else {
        // 如果已经有标记点，则清除
        this.removeMarkers(testPoint);
        this.map.setCenter(this.center); // 移动回默认中心位置
        this.map.setZoom(9.5); // 恢复缩放级别
      }
      // 清除其他点位的标记点
      Object.keys(this.customPoints).forEach((pt) => {
        if (pt !== testPoint) {
          this.removeMarkers(pt);
        }
      });
    },
    togglePolygon() {
      const polygon = createPolygon(this.map, this.center, this.polygonOptions);
      this.polygon = polygon;
      this.hasPolygon = true;
    },
  },
  mounted() {
    this.initMap();
  },
  beforeDestroy() {
    if (this.map) {
      Object.keys(this.markers).forEach((testPoint) => {
        this.removeMarkers(testPoint);
      });
      this.map.destroy();
      this.map = null;
    }
  },
};
</script>

<style lang="less" scoped>
.amapview {
  width: 100%;
  flex-grow: 1; /* 占据剩余空间 */
  position: relative;
}
.map {
  width: 100%;
  height: 100%;
  background-color: rgb(3, 14, 27);
}

.custom-label {
  padding: 5px 10px;
  white-space: nowrap;
}
.btn {
  position: absolute;
  left: 10px;
  z-index: 99;
  padding-top: 10px;
  box-sizing: border-box;
  div {
    cursor: pointer;
    border: 1px solid #ccc;
    background: rgba(0, 0, 0, 0.3);
    text-align: center;
    padding: 5px 10px;
    border-radius: 3px;
    margin-top: 10px;
    img {
      width: 30px;
      height: 30px;
      vertical-align: middle;
    }
    span {
      color: #fff;
      vertical-align: middle;
      margin-left: 10px;
      font-size: 14px;
      cursor: pointer;
    }
  }
  div:nth-child(1) {
    margin-top: 0px;
  }
}
::v-deep .amap-container {
  .amap-marker-label {
    background: transparent;
    border: none;
  }
}
</style>
