<template>
  <div id="app">
    <div :class="$route.name !== 'login' ? 'flexBox' : 'loginBox'">
      <Layout
        :isSidebarCollapsed="isSidebarCollapsed"
        v-if="$route.name !== 'login'"
      ></Layout>
      <div class="app-box">
        <TopBar
          :isSidebarCollapsed="isSidebarCollapsed"
          @toggle-sidebar="toggleSidebar"
        ></TopBar>
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import Layout from "@/components/Layout .vue";
import TopBar from "@/components/TopBar.vue";
export default {
  name: "App",
  components: {
    Layout,
    TopBar,
  },
  data() {
    return {
      timeoutId: null, // 保存 setTimeout 的 ID
      isSidebarCollapsed: false, // 默认不折叠
    };
  },
  watch: {
    $route: function (to, from) {
      console.log(to, from);
      if (from.name === "login") {
        this.startTimer();
      }
      if (to.name === "login") {
        this.clearTimer(); // 添加清除计时器的调用
      }
    },
  },
  mounted() {
    this.updateBodySize();
    window.addEventListener("resize", this.updateBodySize);
  },
  beforeDestroy() {
    this.clearTimer(); // 添加清除计时器的调用
    localStorage.removeItem("token");
    sessionStorage.removeItem("token");
  },
  methods: {
    updateBodySize() {
      document.body.style.width = `${window.innerWidth}px`;
      document.body.style.height = `${window.innerHeight}px`;
      console.log(123, document.body.style.width, document.body.style.height);
    },
    toggleSidebar() {
      this.isSidebarCollapsed = !this.isSidebarCollapsed;
    },
    startTimer() {
      // 设置 24 小时后的计时器
      this.timeoutId = setTimeout(() => {
        this.handleLogout();
      }, 24 * 60 * 60 * 1000); // 24小时毫秒数
    },
    clearTimer() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId); // 清除计时器
      }
    },
    handleLogout() {
      // 清除本地存储的 token
      localStorage.removeItem("token");

      // 显示提示信息，并在 3 秒后重定向到登录页面
      this.$message.error("您的会话已过期，请重新登录！");

      // 在 3 秒后自动重定向到登录页面
      setTimeout(() => {
        this.redirectLogin();
      }, 3000);
    },
    redirectLogin() {
      // 重定向到登录页面
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style lang="less">
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* 防止水平滚动条出现 */
  background-color: #f0f0f0;
}
.app-box {
  width: 100%;
  height: 100%;
      display: flex;
    flex-direction: column;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}
.flexBox {
  width: 100%;
  display: flex;
  height: 100%;
}
.loginBox {
  width: 100%;
  height: 100%;
}
// .Layout {
//   width: 200px;
//   height: 300px;
// }
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
div {
  box-sizing: border-box;
}
.el-pagination {
  text-align: center;
  margin-top: 20px;
}
</style>
