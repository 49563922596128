<template>
  <div>
    <!-- 模态对话框 -->
    <el-dialog
      :visible.sync="localDialogVisible"
      :title="dialogTitle"
      width="80%"
      @close="onClose"
    >
      <el-form :model="form" ref="form" :rules="rules">
        <el-form-item label="用户名" :label-width="formLabelWidth">
          <el-input
            v-model="form.username"
            placeholder="请输入用户名"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" :label-width="formLabelWidth">
          <el-input
            v-model="form.password"
            type="password"
            placeholder="请输入密码"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="用户头像" :label-width="formLabelWidth">
          <Upload v-model="form.avatar" />
        </el-form-item>
        <el-form-item label="用户状态" :label-width="formLabelWidth">
          <el-select v-model.number="form.status" placeholder="是否启用">
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户组" :label-width="formLabelWidth">
          <el-select v-model.number="form.groupId" placeholder="请选择用户组">
            <el-option
              v-for="item in groupOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="saveChanges">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Upload from "@/components/Upload.vue"; // 确保路径正确
import { getUserAdd } from "@/api/api";

export default {
  name: "UserEdit",
  components: {
    Upload,
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    dialogTitle: {
      type: String,
      default: "",
    },
    initialData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      localDialogVisible: false,
      form: {
        username: "",
        password: "",
        avatar: "",
        status: "",
        groupId: 0,
      },
      formLabelWidth: "120px",
      groupOptions: [
        { value: 1, label: "超级管理员" },
        { value: 2, label: "管理员" },
        { value: 3, label: "普通用户" },
      ],
      statusOptions: [
        { value: 0, label: "启用" },
        { value: 1, label: "禁用" },
      ],
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 3,
            max: 15,
            message: "用户名长度应在3到15个字符之间",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 6,
            max: 20,
            message: "密码长度应在6到20个字符之间",
            trigger: "blur",
          },
        ],
        groupId: [
          { required: true, message: "请选择用户组", trigger: "change" },
        ],
      },
    };
  },
  watch: {
    dialogVisible(newVal) {
      this.localDialogVisible = newVal;
      if (newVal) {
        console.log(this.initialData);
        if (this.initialData) {
          // 确保从父组件传递过来的值为数字
          this.form = {
            ...this.initialData,
            status:
              this.initialData.status !== undefined
                ? parseInt(this.initialData.status, 10)
                : 0,
            groupId:
              this.initialData.groupId !== undefined
                ? parseInt(this.initialData.groupId, 10)
                : 0,
          };
        }
      }
    },
  },
  methods: {
    saveChanges() {
      // 发送保存请求到服务器
      const { id, username, password, groupId } = this.form;

      // 检查所有字段是否包含有效内容
      if (!username.trim()) {
        this.$message.error("用户名不能为空");
        return;
      }

      if (!password.trim()) {
        this.$message.error("密码不能为空");
        return;
      }

      if (!groupId) {
        this.$message.error("请选择用户组");
        return;
      }

      // 如果所有字段都有效，则继续保存操作
      console.log(this.form);
      const params = this.form;
      getUserAdd(params).then((res) => {
        console.log(res);
        if (res.code === 1) {
          if (!id) {
            this.$message.success("添加成功");
          } else {
            this.$message.success("修改成功");
          }
        } else {
          this.$message.error(res.message);
        }
        this.closeDialog();
      });
    },
    closeDialog() {
      this.localDialogVisible = false;
      this.$emit("dialogVisibleChanged", false);
    },
    onClose() {
      this.closeDialog();
    },
  },
  created() {
    // 初始化用户组选项
    this.statusOptions = [
      { value: 0, label: "启用" },
      { value: 1, label: "禁用" },
    ];
    this.groupOptions = [
      { value: 1, label: "超级管理员" },
      { value: 2, label: "管理员" },
      { value: 3, label: "普通用户" },
    ];
  },
};
</script>

<style scoped>
/* 样式可以根据需要添加 */
.el-dialog {
  margin-top: 5vh !important;
}
::v-deep .el-form-item__content {
  line-height: normal;
}
.editBox {
  border: 1px solid #c0c4cc;
  border-radius: 2px;
  z-index: 9;
}
</style>
