import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';
import LoginView from '../views/Login.vue'; // 登录视图组件
import NotFoundView from '../views/NotFoundView.vue'; // 假设这是您的 404 视图组件
import OrderGoods from '../views/OrderGoods.vue'; // 订单列表页
import ShopList from '../views/ShopList.vue'; // 商品列表页
import UserView from '../views/UserView.vue'; // 管理员页面
import SetupView from '../views/SetupView.vue'; // 设置
import AMapView from '../views/AMapView.vue'; // 地图页面
import GisView from '../views/webGis.vue'; // gis地图
import AMapAddView from '../components/AMapAdd.vue'; // gis地图

Vue.use(VueRouter);

// 定义路由
const routes = [{
    path: '/',
    name: 'login',
    component: LoginView
  }, {
    path: '/home',
    name: 'home',
    component: HomeView,
    meta: {
      requiresAuth: true
    } // 标记此路由需要认证
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/order',
    name: 'order',
    component: OrderGoods
  },
  {
    path: '/shop',
    name: 'shop',
    component: ShopList
  },
  {
    path: '/user',
    name: 'user',
    component: UserView
  },

  {
    path: '/setup',
    name: 'setup',
    component: SetupView
  },
  {
    path: '/amap',
    name: 'amap',
    component: AMapView
  },
  {
    path: '/amapadd',
    name: 'amapadd',
    component: AMapAddView
  },
  {
    path: '/gis',
    name: 'gis',
    component: GisView
  },
  {
    path: '*',
    name: 'not-found',
    component: NotFoundView
  }
  // 其他路由...
];

// 创建 VueRouter 实例并传入 `routes` 配置
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

// 检查本地存储或其他存储机制以获取 token
function getToken() {
  return localStorage.getItem('token') || sessionStorage.getItem('token');
}

// 设置全局前置守卫
router.beforeEach((to, from, next) => {
  // 如果路由需要认证（即标记了 requiresAuth）
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // 如果没有 token，则重定向到登录页
    if (!getToken()) {
      // 将尝试访问的完整路径作为查询参数，以便登录后重定向
      next({
        path: '/login',
        query: {
          redirect: to.fullPath
        }
      });
    } else {
      // 如果有 token，继续到目标路由
      next();
    }
  } else {
    // 如果路由不需要认证，直接前往目标路由
    next();
  }

  // 如果有 token，且用户试图访问的是登录页面，则重定向到首页
  if (getToken() && to.name === 'login') {
    next({
      name: 'home'
    }); // 直接重定向到首页
  }

  // 如果没有 token，并且试图访问的是首页，则重定向到登录页
  if (!getToken() && to.name === 'home') {
    next({
      name: 'login'
    }); // 直接重定向到登录页
  }
});

// // 在浏览器关闭时清除所有缓存内的 token
// function clearTokenOnUnload() {
//   localStorage.removeItem('token');
//   sessionStorage.removeItem('token');
// }

// // 添加 beforeunload 事件监听器
// window.addEventListener('beforeunload', clearTokenOnUnload);

export default router;
